    .fileCard {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
    }

    .fileCard .icon-wrapper {
        background: #EBF1FE;
        border-radius: 100%;
        padding: 0.8em 1em;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .fileCard .icon-wrapper .icon {
        color: #437ADB;
    }

    .fileCard .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 8px;
        width: 80%;
    }

    .fileCard .content strong {
        color: #000;
        text-overflow: ellipsis;
        /* Required for text-overflow to do anything */
        white-space: nowrap;
        overflow: hidden;
    }

    .infoCard .content p {
        color: #6e7a8d;
        text-overflow: ellipsis;
        /* Required for text-overflow to do anything */
        white-space: nowrap;
        overflow: hidden;
        letter-spacing: 0.5px;
    }

    .fileCard {
        width: 100%;
        max-width: none;
    }

    .fileCard .icon-wrapper {
        border-radius: 8px;
    }

    .fileCard .content {
        flex: 1;
        width: 100%;
    }

    .fileCard .content p {
        text-transform: uppercase;
    }