.wrapper {
  width: 100%;
  height: 100%;
}

.wrapper .auth {
  min-width: 400px;
  max-width: 500px;
  overflow-y: scroll;
  background: #fff;
  padding: 16px 26px;
  border: 1px solid #fff;
}
.wrapper .auth::-webkit-scrollbar {
  display: none;
}
.wrapper .auth form {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.wrapper .auth form h1 {
  font-size: 24px;
  font-family: Medium;
  margin: 10.6666666667px 0;
  flex: 1;
}
.wrapper .auth form .stepBtn {
  color: #9099a8;
  transition: all 0.5s ease;
  cursor: pointer;
}
.wrapper .auth form .stepBtn:hover {
  color: #000;
}
.wrapper .auth form p {
  font-size: 16px;
  color: #77828d;
  text-align: center;
}
.wrapper .auth form a {
  text-decoration: none;
  color: #9099a8;
  transition: all 0.5s ease;
  width: 100%;
  text-align: right;
}
.wrapper .auth form a:hover {
  color: #77828d !important;
  text-decoration: underline !important;
}
.wrapper .auth form .error {
  color: #b63434;
  text-align: center !important;
}
.wrapper .auth form button {
  width: 100%;
}
.wrapper .auth .footer {
  color: #77828d;
  margin-top: 16px;
}
.wrapper .auth .footer a {
  text-decoration: none;
  color: #437adb;
}
.wrapper .auth .footer span {
  color: #437adb;
  cursor: pointer;
  transition: all 0.5s ease;
}
.wrapper .auth .footer span:hover {
  text-decoration: underline;
}
