@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  min-height: 100%;
  font-family: Regular;
}

.app {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.spacer {
  margin: 10.6666666667px;
}

.halfSpacer {
  margin: 3.5555555556px;
}

.error {
  width: 100%;
  text-align: center !important;
  color: #B63434 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  outline: none;
  color: #000 !important;
  -webkit-text-size-adjust: 1.2em;
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

@font-face {
  font-family: Black;
  src: url(../../assets/fonts/Black.otf);
}
@font-face {
  font-family: ExtraBold;
  src: url(../../assets/fonts/ExtraBold.otf);
}
@font-face {
  font-family: Bold;
  src: url(../../assets/fonts/Bold.otf);
}
@font-face {
  font-family: SemiBold;
  src: url(../../assets/fonts/DemiBold.otf);
}
@font-face {
  font-family: Medium;
  src: url(../../assets/fonts/Medium.otf);
}
@font-face {
  font-family: Regular;
  src: url(../../assets/fonts/Regular.otf);
}
@font-face {
  font-family: Thin;
  src: url(../../assets/fonts/Thin.otf);
}
@font-face {
  font-family: Light;
  src: url(../../assets/fonts/Light.otf);
}
.absolute-fill {
  position: absolute;
  width: 100%;
  height: 100%;
}

.flexer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  position: absolute;
  top: 10%;
  left: 10%;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 500px) {
  .logo {
    left: 50%;
  }
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.home {
  width: 100%;
  height: 100%;
  display: flex;
}
.home .parent {
  flex: 1;
  position: relative;
  padding: 96px 192px 16px 192px;
  overflow-y: scroll;
  transition: all 0.5s ease;
}
.home .parent header {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 32px;
  background: linear-gradient(#FFF, transparent);
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
}
.home .parent .step {
  color: #9099A8;
  font-family: Medium;
  letter-spacing: 1px;
}
.home .parent h3 {
  font-family: Medium;
  margin: 0 !important;
}
.home .parent p {
  color: #9099A8;
}
.home .parent .addbtn {
  width: 100%;
  color: #1e4b9a;
  transition: all .5s;
  background: white;
}
.home .parent .addbtn:hover{
  background: #EBF1FE;
}
.home .parent .member {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 100%;
  background: #F1F2F4 !important;
  border: none !important;
  margin-right: 16px;
}
.home .parent .member p {
  text-transform: uppercase;
  font-size: 16px;
  margin: 0;
  color: #222 !important;
  cursor: pointer;
}
.home .parent .member .icon {
  border-radius: 100%;
  background: #E4E6EA !important;
  border: 2px solid #fff;
  font-size: 16px;
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
}
.home .parent .mainBtn {
  display: none;
}