.TeamActions{
  padding: 5px;
  cursor: pointer !important;
}
.TeamActions:hover {
  background: #ECF2FB;
}
.TeamActions:hover .icon,.TeamActions:hover label{
  color:#437ADB !important;
}

.TeamActions .icon, .TeamActions label {
  color:#9099A8 !important;
  cursor: pointer !important;
}

.project .TeamMember .content {
  padding: 0 10.6666666667px;
  margin-top: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: flex-end;
}
.project .TeamMember .content h4 {
  margin: 0;
  font-size: 1em;
  font-family: Medium;
  text-transform: capitalize;
}
.project .TeamMember .content p {
  color: #9099A8 !important;
  font-family: Regular;
  text-transform: capitalize;
}

.project .TeamMember .content p:hover{
  color: #9099A8 !important;
}
