@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  min-height: 100%;
  font-family: Regular;
}

.app {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.spacer {
  margin: 10.6666666667px;
}

.halfSpacer {
  margin: 3.5555555556px;
}

.error {
  width: 100%;
  text-align: center;
  color: #B63434 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  outline: none;
  color: #000 !important;
  -webkit-text-size-adjust: 1.2em;
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

@font-face {
  font-family: Black;
  src: url(../../assets/fonts/Black.otf);
}
@font-face {
  font-family: ExtraBold;
  src: url(../../assets/fonts/ExtraBold.otf);
}
@font-face {
  font-family: Bold;
  src: url(../../assets/fonts/Bold.otf);
}
@font-face {
  font-family: SemiBold;
  src: url(../../assets/fonts/DemiBold.otf);
}
@font-face {
  font-family: Medium;
  src: url(../../assets/fonts/Medium.otf);
}
@font-face {
  font-family: Regular;
  src: url(../../assets/fonts/Regular.otf);
}
@font-face {
  font-family: Thin;
  src: url(../../assets/fonts/Thin.otf);
}
@font-face {
  font-family: Light;
  src: url(../../assets/fonts/Light.otf);
}

.absolute-fill {
  position: absolute;
  width: 100%;
  height: 100%;
}

.flexer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  position: absolute;
  top: 10%;
  left: 10%;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 500px) {
  .logo {
    left: 50%;
  }
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.profile {
  background: #E6E9ED;
  overflow: hidden;
  height: 100vh;
  width: 100%;
}
@media screen and (min-width:701px) {
  .profile .fluid {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    height: 100%;
    flex-direction: column;
  }
}
@media screen and (max-width:700px) {
  .profile .fluid{
    display: grid !important;
  }
}
.profile header {
  width: 100%;
  min-height: 25vh;
  max-height: 25vh;
  margin-top: 3vh;
  overflow: hidden;
  position: relative;
  background: white;
  border-radius: 11px;
}
.profile header .overlay {
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 60%;
  position: absolute;
}
.profile header .content {
  align-items: flex-end;
  position: absolute;
  display: flex;
  height: 100%;
  width: 100%;
  z-index: 1;
  bottom: 0;
  left: 0;
  padding: 16px 32px;
}
.profile header .content .icon-wrapper {
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background: #E6E9ED;
  height: 120px;
  width: 120px;
  margin-right: 13px;
  border: 2px solid white;
}
.profile header .content .icon-wrapper img {
  width: 100%;
  -o-object-position: top;
     object-position: top;
  -o-object-fit: cover;
     object-fit: cover;
}
.profile header .content h3 {
  margin: 0;
  text-transform: capitalize;
}
.profile header .content p {
  color: #9099A8;
  font-family: Regular;
}
.profile nav {
  align-items: center;
  justify-content: space-between;
  margin: 16px 0;
  justify-content: flex-start;
  border-bottom: 2px solid #b8bec7;
}
.profile nav a, .profile nav p {
  margin-right: 32px;
  position: relative;
  padding: 0.8em 0;
  text-decoration: none;
  transition: all 0.5s ease;
  font-family: Medium;
  color: #555;
}
.profile nav a:hover, .profile nav p:hover {
  color: #222;
}
.profile nav .editor {
  flex: 1;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: flex-end;
}
.profile nav .editor .icon {
  margin-left: 8px;
}
.profile nav .editor p {
  margin-right: 0;
  margin-left: .5em;
  text-align: right;
  text-transform: capitalize;
}
.profile nav .editor .icon, .profile nav .editor p {
  color: #a19f9f !important;
  transition: all .3s ease;
  font-weight:200 !important;
}
.profile nav .editor:hover .icon, .profile nav .editor:hover p {
  color: #777 !important;
}
.profile nav .active {
  color: #437ADB;
}
.profile nav .active:hover {
  color: dodgerblue;
}
.profile nav .active:before {
  content: "";
  position: absolute;
  width: 50px;
  height: 2px;
  left: 50%;
  bottom: -3%;
  transform: translateX(-50%);
  transition: all 0.5s ease;
  background: #437ADB;
  -webkit-animation: opacify 0.5s ease;
          animation: opacify 0.5s ease;
}

@media screen and (max-width:800px) {

  .profile {
    overflow-y: scroll !important;
  }
  .profile header {
    width: 95% !important;
    margin: 2vh auto 0 auto;
    min-height: 250px;
    /* height: 250px; */
  }
  .profile header .overlay{
    background: linear-gradient(to bottom, #8399a279, transparent);
  }
  .profile header .content {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
  }

  .profile header .content .icon-wrapper {
    margin: 0;
  }
}
.sticky{
  background: linear-gradient(#E6E9ED 10%,#E6E9ED 60%,rgba(0,0,0,0) ) !important;
}
