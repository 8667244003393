.portfolio .node .container {
  display: flex;
  flex-direction: column;
}

.portfolio .node .container .image{
  width: 100%;
  height: 250px;
}
.portfolio .node .container .image img {
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
}
.portfolio .node .container p {
  color: #9099A8;
}
.current .back-handler {
  cursor: pointer;
  display: flex;
  align-items: flex-start;
}
.current .back-handler p, .current .back-handler .icon {
  color: #586169;
  display: flex;
  transition: all 0.5s ease;
}
.current .back-handler .icon {
  margin: 0.2em 0.3em;
}
.current .back-handler:hover p, .current .back-handler:hover .icon {
  color: #000;
}
.current section {
  display: flex;
  align-items: flex-start;
}
.current section img {
  width: 35px;
  padding: 0.5em;
  background: white;
  border-radius: 100%;
}
.current section h3 {
  font-family: SemiBold;
}
.current section p {
  color: #5b636c;
}
.current section .exit {
  margin: 0 !important;
}
.current img {
  border-radius: 11px;
  width: 100%;
}
.current .gallery {
  min-width: 100%;
  max-width: 100%;
  /* height: 430px; */
  overflow-y:scroll;
  overflow-x: hidden;
  margin: 32px 0 16px 0;
  display: grid;
  gap: 14px;
  grid-template-rows: minmax(100px, auto);
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-auto-flow: dense;
}

.current .gallery div {
  height: 250px;
}

.current .img4, .current .img2 {
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
}

.current .img1 {
  grid-template-columns: repeat(auto-fill, minmax(1100px, 1fr));
}

.current .img5  {
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

.current .img5 div:nth-child(1) {
  grid-column: span 2;
  grid-row: span 2;
  height: 93%;
}

.current .img1 div, .current .img2 div {
  height: 300px;
}

.current .gallery p {
  font-family: Regular;
}

@media screen and (max-width:700px) {
  .current{
    padding: 0 1em;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  .current .gallery {
    display: flex;
    overflow-x: scroll !important;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    height: 220px;
  }

  .current .gallery div {
    width: 90vw;
    height: 100%;
  }
  .portfolio .node .container .image{
    width: 100%;
    height: 190px;
  }
}
