@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  min-height: 100%;
  font-family: Regular;
}

.app {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.spacer {
  margin: 10.6666666667px;
}

.halfSpacer {
  margin: 3.5555555556px;
}

.inputError {
  width: 100%;
  font-size: .9em;
  text-align: left !important;
  color: #B63434 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  outline: none;
  color: #000 !important;
  -webkit-text-size-adjust: 1.2em;
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

@font-face {
  font-family: Black;
  src: url(../../assets/fonts/Black.otf);
}
@font-face {
  font-family: ExtraBold;
  src: url(../../assets/fonts/ExtraBold.otf);
}
@font-face {
  font-family: Bold;
  src: url(../../assets/fonts/Bold.otf);
}
@font-face {
  font-family: SemiBold;
  src: url(../../assets/fonts/DemiBold.otf);
}
@font-face {
  font-family: Medium;
  src: url(../../assets/fonts/Medium.otf);
}
@font-face {
  font-family: Regular;
  src: url(../../assets/fonts/Regular.otf);
}
@font-face {
  font-family: Thin;
  src: url(../../assets/fonts/Thin.otf);
}
@font-face {
  font-family: Light;
  src: url(../../assets/fonts/Light.otf);
}

.absolute-fill {
  position: absolute;
  width: 100%;
  height: 100%;
}

.flexer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  position: absolute;
  top: 10%;
  left: 10%;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 500px) {
  .logo {
    left: 50%;
  }
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.LabelPicker {
  width: 100%;
  display: flex;
  position: relative;
  margin: 8px 0;
  flex-direction: column;
}
.LabelPicker label {
  font-size: 14px;
  font-family: Medium;
  color: #9099A8 !important;
}
.LabelPicker .icon {
  color: #9099A8 !important;
  transition: all 0.5s ease;
  cursor: pointer;
}
.LabelPicker .icon:hover {
  color: #77828D !important;
}
.LabelPicker .locations {
  padding: 5px;
  margin-top: 8px;
  border: 1px solid #9099A8;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px !important;
  flex: 1;
  position: relative;
  display: flex;
  overflow: hidden;
}
.LabelPicker .locations .toggleIcon {
  position: absolute;
  top: 50%;
  left: 96%;
  transform: translate(-50%, -50%);
  background: #FFF;
}
.LabelPicker .locations .placeholder {
  padding: 10px 15px;
  background: #FFF;
  color: #9099A8 !important;
  cursor: pointer;
  flex: 1;
}
.LabelPicker .locations .chosen {
  padding: 8px 10px;
  background: #EBF1FE;
  border-radius: 6px;
  margin-right: 5px;
  cursor: pointer;
}
.LabelPicker .locations .chosen:hover{
  opacity: .8;
  transition: all .5s;
}
.LabelPicker .locations .chosen p {
  margin: 0;
  color: #437ADB;
  margin-right: 10px;
}
.LabelPicker .locations .chosen .icon {
  color: #9099A8 !important;
  transition: all 0.5s ease;
}
.LabelPicker .Label-Modal {
  position: absolute;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  top: 105%;
  left: 0;
  z-index: 20;
  overflow: hidden;
  background: #FFF;
  border-radius: 11px;
  box-shadow: 0 0 1em #F1F2F4;
  padding: 6px 15px;
}
.LabelPicker .Label-Modal .choice {
  padding: 10.6666666667px 25.6px;
  transition: all 0.5s ease;
  margin: 0;
  border-radius: 6px;
  cursor: pointer;
}
.LabelPicker .Label-Modal .choice:hover {
  background: #EBF1FE;
  color: #437ADB;
}
.LabelPicker .Label-Modal .choices {
  max-height: 30vh;
  overflow-y: scroll;
}
